import * as React from 'react'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import {Link} from 'gatsby'

import products from '../../content/products.json'

export default function ProductsTemplate() {
  const affiliateQuery = '?affiliates=76603'

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Los verbos"
        description="Descubre artículos informativos sobre la lengua y cultura francesa. Aprende francés con consejos y recursos útiles en el blog de Lemot."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">
            Productos Educativos para Aprender Francés: Recursos para Todos los
            Niveles
          </h1>
        </section>
        <section className="section is-small">
          <div
            className="columns is-multiline"
            style={{marginBottom: '1.5rem'}}
          >
            {products.map(product => {
              return (
                <div
                  className="column is-one-third"
                  key={product.slug}
                  style={{marginBottom: '2rem'}}
                >
                  <Link to={`${product.url}${affiliateQuery}`}>
                    <div
                      style={{
                        minHeight: 150,
                      }}
                    >
                      <h3
                        style={{height: 45}}
                        className="title is-5 has-text-centered"
                      >
                        {product?.title}
                      </h3>
                      <div className="card-image">
                        <figure className="image">
                          <img
                            src={`/assets/products/${product.fileName}`}
                            alt={product.fileName}
                          />
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="has-text-weight-bold top-bottom-overflow-fade has-text-centered">
                          <span>{product.description}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    </Layout>
  )
}
